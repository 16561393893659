<template>
  <div class="div">
    <b-img v-if="!options" :src="this.$loaderImage" height="20" />

    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
      v-if="options"
    >
      <!-- general tab -->
      <b-tab active>
        <!-- title -->
        <template #title>
          <feather-icon icon="UserIcon" size="18" class="mr-50" />
          <span class="font-weight-bold"> General </span>
        </template>

        <account-setting-general
          v-if="options.general"
          :general-data="options.general"
        />
      </b-tab>
      <!--/ general tab -->

      <!-- change password tab -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="LockIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Officiel</span>
        </template>

        <account-setting-official
          v-if="options.official"
          :official-data="options.official"
        />
      </b-tab>
      <!--/ change password tab -->

      <!-- info -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="InfoIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Information</span>
        </template>

        <account-setting-information
          v-if="options.info"
          :information-data="options.info"
        />
      </b-tab>

      <!-- social links -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="LinkIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Social</span>
        </template>

        <account-setting-social
          v-if="options.social"
          :social-data="options.social"
        />
      </b-tab>

      <!-- notification -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="BellIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Notifications</span>
        </template>

        <account-setting-notification
          v-if="options.notification"
          :notification-data="options.notification"
        />
      </b-tab> 
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BImg } from "bootstrap-vue";
import AccountSettingGeneral from "./corporation/CorporationSettingGeneral.vue";
import AccountSettingOfficial from "./corporation/CorporationSettingOfficial.vue";
import AccountSettingInformation from "./corporation/CorporationSettingInformation.vue";
import AccountSettingSocial from "./corporation/CorporationSettingSocial.vue";
import AccountSettingNotification from "./corporation/CorporationSettingNotification.vue";
import AccountSettingHoliday from "./corporation/CorporationSettingHoliday.vue";

export default {
  components: {
    BTabs,
    BTab,
    BImg,
    AccountSettingGeneral,
    AccountSettingOfficial,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettingNotification,
    AccountSettingHoliday,
  },
  data() {
    return {
      options: {},
    };
  },
  async beforeCreate() {
    var userData = JSON.parse(localStorage.getItem("userData"));

    await myApi
      .get(`corporation/0`)
      .then((response) => {
        // default action 
        var corporationData = response.data.data;

        var cData = {
          general: {
            logo_url: corporationData.logo_url,
            corporation_name: corporationData.corporation_name,
            corporation_name_mini: corporationData.corporation_name_mini,
            ceo: corporationData.ceo,
            email: corporationData.email,
            phone: corporationData.phone,
            country: corporationData.country,
            state: corporationData.state,
            town: corporationData.town,
            employee_capacity: corporationData.employee_capacity,
            adresse: corporationData.adresse,
            send_message_task: corporationData.send_message_task,
          },
          official: {
            legal: corporationData.legal,
            idnat: corporationData.idnat,
            rccm: corporationData.rccm,
            impot: corporationData.impot,
          },
          info: {
            description: corporationData.description,
            slogan: corporationData.slogan,
          },
          social: {
            twitter: corporationData.twitter,
            facebook: corporationData.facebook,
            google: corporationData.google,
            linkedIn: corporationData.linkedIn,
            instagram: corporationData.instagram,
            quora: "",
          },
          notification: {
            alert_sale: corporationData.alert_sale,
            alert_expense: corporationData.alert_expense,
            alert_payment: corporationData.alert_payment,
            alert_non_attendance: corporationData.alert_non_attendance,
            alert_loss: corporationData.alert_loss,
            alert_stock: corporationData.alert_stock,
            alert_sms: corporationData.alert_sms,
            alert_email: corporationData.alert_email,
            alert_contact: corporationData.alert_contact,
            alert_fake_account: corporationData.alert_fake_account,
          },
          holiday: {
            paid_holiday: corporationData.paid_holiday,
            paid_holiday_interval: corporationData.paid_holiday_interval,
          },
        };

        this.options = cData;
      })
      .catch((error) => {
        //
        console.log(error);
      });

    // this.$http.get("/account-setting/data").then((res) => {
    //   this.options = res.data;
    // });
  },
};
</script>
